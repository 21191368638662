import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from '../../../../components';
import { useOrgForm } from './useOrgForm';

import name from '../../icons/location.svg';
import email from '../../icons/email.svg';
import orgIcon from '../../icons/org.svg';
import url from '../../icons/url.svg';
import stripeIcon from '../../icons/stripe_icon.svg';
import orgSeats from '../../icons/orgSeats.svg';
import { Features, Organization } from '../../../../types';
import {
  clearOrgErrorMessage,
  createOrgSuccess,
} from '../../../../store/orgs/orgs.actions';
import {
  ButtonType,
  ButtonVariant,
} from '../../../../components/Button/Button';
import { ToggleRow } from '../ToggleRow/ToggleRow';
import { LoaderIcon } from '../../../../components/Icons/LoaderIcon';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getStripeCustomerId } from '../../../../store/orgs/orgs.thunks';
import {
  clearStripeCustomerId,
  clearStripeCustomerIdErrors,
} from '../../../../store/orgs/orgs.slice';

type AddOrganizationProps = {
  open: boolean;
  onClose: () => void;
  isAdd?: boolean;
  org?: Organization | null;
  isMyOrgEdit?: boolean;
};

export const AddOrganization: React.FC<AddOrganizationProps> = ({
  onClose,
  open,
  isAdd = false,
  org,
  isMyOrgEdit = false,
}: AddOrganizationProps): JSX.Element => {
  const {
    errors,
    handleCancel,
    handleChange,
    handleSubmit,
    setFieldValue,
    touched,
    values,
    isErrorMsg,
    isOrgCreated,
    isLoading,
  } = useOrgForm({ onClose, isAdd, org, isMyOrgEdit });

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const dispatch = useAppDispatch();

  const currentOrg = useAppSelector((state) => state.orgs.currentOrg);

  const isCreateOrgError = useAppSelector(
    (state) => state.orgsNew.isError.createOrg
  );

  const isUpdateStripeCustomerIdError = useAppSelector(
    (state) => state.orgsNew.isError.updateStripeCustomerId
  );

  useEffect(() => {
    if (isErrorMsg || isCreateOrgError) {
      setConfirmModalOpen(false);
    }
  }, [isCreateOrgError, isErrorMsg]);

  useEffect(() => {
    if (isErrorMsg) {
      dispatch(clearOrgErrorMessage());
    } // eslint-disable-next-line
  }, [values.email, values.orgName, dispatch]);

  useEffect(() => {
    if (isCreateOrgError || isUpdateStripeCustomerIdError) {
      dispatch(clearStripeCustomerIdErrors());
    } // eslint-disable-next-line
  }, [values.stripeCustomerId, dispatch]);

  useEffect(() => {
    if (isOrgCreated && isAdd) {
      // onClose();
      // handleCancel();
      setConfirmModalOpen(false);
      setSuccessModalOpen(true);
      // dispatch(createOrgSuccess(false));
    } // eslint-disable-next-line
  }, [isOrgCreated, dispatch]);

  useEffect(() => {
    const request =
      currentOrg && !isAdd
        ? dispatch(getStripeCustomerId(currentOrg.orgId))
        : null;

    return () => {
      request?.abort();
      dispatch(clearStripeCustomerId());
    };
  }, [currentOrg, dispatch, isAdd]);

  const nameFieldError = () => {
    let message;
    if (touched.orgName) message = errors.orgName;
    if (isErrorMsg) {
      message = 'An organization with this name or email already exists';
    }
    return message;
  };

  const cancelAndClose = () => {
    handleCancel();
    setConfirmModalOpen(false);
  };

  const onClickSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      isAdd &&
      !confirmModalOpen &&
      values.email &&
      values.orgName &&
      values.url
    ) {
      setConfirmModalOpen(true);
    } else handleSubmit(e);
  };

  const closeAfterSuccess = () => {
    onClose();
    handleCancel();
    setConfirmModalOpen(false);
    setSuccessModalOpen(true);
    dispatch(createOrgSuccess(false));
  };

  const attrs = {
    wrapper: {
      className: 'add-org',
    },
    confirm: {
      className: 'add-org-confirm',
    },
    title: {
      className: 'add-org__title',
    },
    buttonForm: {
      className: 'add-org__button-form',
      onSubmit: onClickSubmit,
    },
    form: {
      onSubmit: onClickSubmit,
    },
    leftSection: {
      wrapper: {
        className: 'add-org__left-section',
      },
      inputs: {
        orgName: {
          error: nameFieldError(),
          onChange: handleChange,
          value: values.orgName,
          name: 'orgName',
          icon: orgIcon,
          className: nameFieldError()
            ? 'error add-org__left-section__input'
            : 'add-user__left-section__input',
          placeholder: 'Organization name*',
          disabled: !isAdd,
        },
        url: {
          error: touched.url ? errors.url : '',
          onChange: handleChange,
          value: values.url,
          name: 'url',
          icon: url,
          className: 'add-org__left-section__input',
          placeholder: 'URL*',
        },
        email: {
          error: touched.email ? errors.email : '',
          onChange: handleChange,
          value: values.email,
          name: 'email',
          icon: email,
          className: 'add-org__left-section__input',
          placeholder: 'Primary email*',
          disabled: !isAdd,
        },
        firstName: {
          error: touched.firstName ? errors.firstName : '',
          onChange: handleChange,
          value: values.firstName,
          name: 'firstName',
          icon: name,
          className: 'add-org__left-section__input',
          placeholder: 'First name*',
          disabled: !isAdd,
        },
        lastName: {
          error: touched.lastName ? errors.lastName : '',
          onChange: handleChange,
          value: values.lastName,
          name: 'lastName',
          icon: name,
          className: 'add-org__left-section__input',
          placeholder: 'Last name',
          disabled: !isAdd,
        },
        orgSeats: {
          error: touched.orgSeats ? errors.orgSeats : '',
          onChange: handleChange,
          value: values.orgSeats as string,
          name: 'orgSeats',
          placeholder: 'Number of seats',
          icon: orgSeats,
          className: 'add-org__left-section__input',
        },
        acquisitionSource: {
          onChange: handleChange,
          value: values.acquisitionSource as string,
          name: 'acquisitionSource',
          placeholder: 'Acquisition source',
          icon: url,
          className: 'add-org__left-section__input',
        },
        stripeCustomerId: {
          error:
            isUpdateStripeCustomerIdError || isCreateOrgError
              ? 'Failed to update'
              : '',
          onChange: handleChange,
          value: values.stripeCustomerId as string,
          name: 'stripeCustomerId',
          placeholder: 'Stripe customer ID',
          icon: stripeIcon,
          className: 'add-org__left-section__input',
        },
      },
    },
    rightSection: {
      wrapper: {
        className: 'add-org__right-section',
      },
      header: {
        className: 'add-org__right-section__header',
      },
      body: {
        wrapper: {
          className: 'add-org__right-section__body',
        },
        toggleRows: {
          main: {
            featureName: 'Features',
            isHeader: true,
            isMainSwitchActive: !Object.values(values).slice(5).includes(false),
            setValue: setFieldValue,
          },
          account: {
            featureName: 'My Account & Settings',
            defaultActive: true,
            disabled: true,
            className: 'disabled add-org__right-section__body-row',
          },
          org: {
            featureName: 'My Organization',
            defaultActive: true,
            disabled: true,
            className: 'disabled add-org__right-section__body-row',
          },
          apps: {
            featureName: 'My Apps',
            defaultActive: true,
            disabled: true,
            className: 'with-border disabled add-org__right-section__body-row',
          },
          soundWave: {
            formName: Features.SOUNDWAVE,
            setValue: setFieldValue,
            defaultActive: values.soundwave,
            featureName: 'Sound wave',
            className: 'add-org__right-section__body-row',
          },
          emailAnalyzer: {
            formName: Features.EMAIL_ANALYZER,
            setValue: setFieldValue,
            defaultActive: values.emailAnalyzer,
            featureName: 'Analyze',
            className: 'add-org__right-section__body-row',
          },
          emailSimulator: {
            formName: Features.EMAIL_SIMULATOR,
            setValue: setFieldValue,
            defaultActive: values.emailSimulator,
            featureName: 'Simulate',
            className: 'add-org__right-section__body-row',
          },
          inlineMenu: {
            formName: Features.INLINE_MENU,
            setValue: setFieldValue,
            defaultActive: values.inlineMenu,
            featureName: 'Inline menu',
            className: 'add-org__right-section__body-row',
          },
          myEngagements: {
            formName: Features.MY_ENGAGEMENTS,
            setValue: setFieldValue,
            defaultActive: values.myEngagements,
            featureName: 'My Deals',
            className: 'add-org__right-section__body-row',
          },
          insights: {
            formName: Features.MY_INSIGHTS,
            setValue: setFieldValue,
            defaultActive: values.myInsights,
            featureName: 'My Insights',
            className: 'add-org__right-section__body-row',
          },
          analytics: {
            formName: Features.MY_ANALYTICS,
            setValue: setFieldValue,
            defaultActive: values.myAnalytics,
            featureName: 'My Analytics',
            className: 'add-org__right-section__body-row',
          },
          teams: {
            formName: Features.TEAMS,
            setValue: setFieldValue,
            defaultActive: values.teams,
            featureName: 'Teams',
            className: 'add-org__right-section__body-row',
          },
          teamAnalytics: {
            formName: Features.TEAM_ANALYTICS,
            setValue: setFieldValue,
            defaultActive: values.teamAnalytics,
            featureName: 'Team Analytics',
            className: 'add-org__right-section__body-row',
          },
          eventNotifications: {
            formName: Features.EVENT_NOTIFICATIONS,
            setValue: setFieldValue,
            defaultActive: values.eventNotifications,
            featureName: 'Event Notifications',
            className: 'add-org__right-section__body-row',
          },
          xRayAnalysis: {
            formName: Features.X_RAY_ANALYSIS,
            setValue: setFieldValue,
            defaultActive: values.xRayAnalysis,
            featureName: 'X-Ray Analysis',
            className: 'add-org__right-section__body-row',
          },
          xRaySimulation: {
            formName: Features.X_RAY_SIMULATION,
            setValue: setFieldValue,
            defaultActive: values.xRaySimulation,
            featureName: 'X-Ray Simulation',
            className: 'add-org__right-section__body-row',
          },
          webhooks: {
            formName: Features.WEBHOOKS,
            setValue: setFieldValue,
            defaultActive: values.webhooks,
            featureName: 'Webhooks',
            className: 'add-org__right-section__body-row',
          },
        },
      },
    },
    buttons: {
      save: {
        type: 'submit' as ButtonType,
        className: 'add-org__btn',
        variant: 'common' as ButtonVariant,
        disabled: isLoading || isErrorMsg || isCreateOrgError,
      },
      cancel: {
        className: 'add-org__btn-last',
        variant: 'text' as ButtonVariant,
        onClick: cancelAndClose,
      },
      create: {
        type: 'submit' as ButtonType,
        className: 'add-org__btn-create',
        variant: 'common' as ButtonVariant,
        onclick: handleSubmit,
      },
      edit: {
        className: 'add-org__btn-last',
        variant: 'text' as ButtonVariant,
        onClick: () => setConfirmModalOpen(false),
      },
      loader: {
        className: 'add-org__btn-loader',
      },
    },
    confirmModal: {
      open: confirmModalOpen,
      title: 'Create organization',
      onClose: cancelAndClose,
      width: '456px',
    },
    modalBody: {
      className: 'add-org-confirm__modal__body',
    },
    boldSpan: {
      className: 'add-org-confirm__modal__body-bold',
    },

    successModal: {
      Modal: {
        title: '',
        open: successModalOpen,
        onClose: closeAfterSuccess,
        width: '330px',
      },
      wrapper: { className: 'success add-org-confirm__modal__body' },
      message: { className: 'add-org-confirm__modal__body-bold' },
      button: {
        className: 'add-org__btn-create',
        variant: 'common' as ButtonVariant,
        onClick: closeAfterSuccess,
      },
    },
  };

  const successModal =
    isOrgCreated && isAdd ? (
      <Modal {...attrs.successModal.Modal}>
        <div {...attrs.successModal.wrapper}>
          Organization{' '}
          <span {...attrs.successModal.message}>{values.orgName}</span> with
          primary email{' '}
          <span {...attrs.successModal.message}>{values.email}</span> and with a
          total of
          <span {...attrs.successModal.message}>
            {' '}
            {values.orgSeats || 5} seats was created.
          </span>
          <Button {...attrs.successModal.button}>Got it</Button>
        </div>
      </Modal>
    ) : null;

  if (isOrgCreated && isAdd) return <>{successModal}</>;

  return (
    <Modal
      title={isAdd ? 'Add New Organization' : 'Edit Organization'}
      open={open}
      onClose={cancelAndClose}
      width={confirmModalOpen ? '330px' : '880px'}
    >
      <>
        {confirmModalOpen ? (
          <div {...attrs.modalBody}>
            You are about to create the organization{' '}
            <span {...attrs.boldSpan}>{values.orgName}</span> with primary email{' '}
            <span {...attrs.boldSpan}>{values.email}</span> and with a total of
            <span {...attrs.boldSpan}> {values.orgSeats || 5} seats</span>
            <form {...attrs.form}>
              <Button {...attrs.buttons.create}>
                {isLoading ? (
                  <LoaderIcon {...attrs.buttons.loader} />
                ) : (
                  <>Create</>
                )}
              </Button>
              {isLoading ? (
                <></>
              ) : (
                <Button {...attrs.buttons.edit}>Edit</Button>
              )}
            </form>
          </div>
        ) : (
          <div {...attrs.wrapper}>
            <div {...attrs.leftSection.wrapper}>
              <form {...attrs.form}>
                <Input {...attrs.leftSection.inputs.orgName} />
                <Input {...attrs.leftSection.inputs.url} />
                <Input {...attrs.leftSection.inputs.email} />
                <Input {...attrs.leftSection.inputs.firstName} />
                <Input {...attrs.leftSection.inputs.lastName} />
                <Input {...attrs.leftSection.inputs.orgSeats} />
                <Input {...attrs.leftSection.inputs.acquisitionSource} />
                <Input {...attrs.leftSection.inputs.stripeCustomerId} />
              </form>
            </div>
            <div {...attrs.rightSection.wrapper}>
              <form {...attrs.form}>
                <div {...attrs.rightSection.header}>
                  <ToggleRow {...attrs.rightSection.body.toggleRows.main} />
                </div>
                <div {...attrs.rightSection.body.wrapper}>
                  <ToggleRow {...attrs.rightSection.body.toggleRows.account} />
                  <ToggleRow {...attrs.rightSection.body.toggleRows.org} />
                  <ToggleRow {...attrs.rightSection.body.toggleRows.apps} />
                  <ToggleRow
                    {...attrs.rightSection.body.toggleRows.soundWave}
                  />
                  <ToggleRow
                    {...attrs.rightSection.body.toggleRows.emailAnalyzer}
                  />
                  <ToggleRow
                    {...attrs.rightSection.body.toggleRows.emailSimulator}
                  />
                  <ToggleRow
                    {...attrs.rightSection.body.toggleRows.inlineMenu}
                  />
                  <ToggleRow
                    {...attrs.rightSection.body.toggleRows.myEngagements}
                  />
                  <ToggleRow {...attrs.rightSection.body.toggleRows.insights} />
                  <ToggleRow
                    {...attrs.rightSection.body.toggleRows.analytics}
                  />
                  <ToggleRow {...attrs.rightSection.body.toggleRows.teams} />
                  <ToggleRow
                    {...attrs.rightSection.body.toggleRows.teamAnalytics}
                  />
                  <ToggleRow
                    {...attrs.rightSection.body.toggleRows.eventNotifications}
                  />
                  <ToggleRow
                    {...attrs.rightSection.body.toggleRows.xRayAnalysis}
                  />
                  <ToggleRow
                    {...attrs.rightSection.body.toggleRows.xRaySimulation}
                  />
                  <ToggleRow {...attrs.rightSection.body.toggleRows.webhooks} />
                </div>
              </form>
            </div>
            <form {...attrs.buttonForm}>
              <Button {...attrs.buttons.save}>
                {isLoading ? (
                  <LoaderIcon {...attrs.buttons.loader} />
                ) : (
                  <>{isAdd ? 'Add Organization ' : 'Save Changes'}</>
                )}
              </Button>
            </form>
          </div>
        )}
      </>
    </Modal>
  );
};
